* {
  box-sizing: border-box;
}
body{
  background-image: linear-gradient(transparent, transparent 15%), url('./img/bg copy.jpg');
  background-size:cover;
  /** background image credit: https://www.pexels.com/photo/close-up-of-blooming-cherry-tree-8336114/**/
}
@font-face {
  font-family: 'Gilroy-Bold';
  src: local('Gilroy-Bold'), url(./fonts/Gilroy-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Gilroy-Medium';
  src: local('Gilroy-Medium'), url(./fonts/Gilroy-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Gilroy-Regular';
  src: local('Gilroy-Regular'), url(./fonts/Gilroy-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Gilroy-RegularItalic';
  src: local('Gilroy-RegularItalic'), url(./fonts/Gilroy-RegularItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Gilroy-Light';
  src: local('Gilroy-Light'), url(./fonts/Gilroy-Light.ttf) format('truetype');
}

.App{
  padding:5%;

}
.tree{
  display: block;
  margin:auto;
  text-align: center;
}
.apple{
  width:165px;
  height:165px;
  margin:20px;
  padding:10px;
  vertical-align: middle;
  font-family: 'Gilroy-Bold', sans-serif;
  padding-top:66px;
  font-size: 16px;
  text-align: center;
  display: inline-block;
  background-size:contain;
}
.subtitle{
  font-family: 'Gilroy-Medium', sans-serif;
  font-size:12px;
}
.header{
  text-align: center;
  display: block;
  margin:auto;
}
.pre{
  font-family: 'Gilroy-Regular', sans-serif;
  font-size:17px;
  text-align: center;
  display: block;
  letter-spacing:1px;
  margin:auto;
}
h1{
  display: none;
}
h2,a.MuiButtonBase-root{
  font-family: 'Gilroy-Bold', sans-serif !important;
}
p{
  margin-top:15px;
  font-family: 'Gilroy-Regular', sans-serif !important;
}
h3{
  font-family: 'Gilroy-Bold', sans-serif;
  font-size:28px;
  text-align: center;
  letter-spacing: 3px;
}
.ruby{
  font-weight: bold;
  color:"#b32e47" !important;
}
.gold{
  font-weight: bold;
  color:"#c9ba59" !important;
}
#credits{
  font-family: 'Gilroy-RegularItalic', sans-serif !important;
}
.link{
  text-decoration: none;
  font-weight: bold;
  color:#888;
}
.link:visited{
  text-decoration: none;
}
.link:hover{
  color:#444;
}
@media (max-width: 456px){
  .apple{
    width:145px;
    height:145px;
    padding-top:60px !important;
    margin: 8px;
  }
}